'use client';

import { useReactiveVar } from '@apollo/client';
import Tooltip from 'antd/lib/tooltip';
import { memo } from 'react';
import { FaVolumeMute, FaVolumeUp } from 'react-icons/fa';
import styled from 'styled-components';

import { alertSoundMutedVar } from '~/apollo/reactiveVariables/alertSoundMutedVar';
import i18n from '~/locales/i18n';
import { muteAlarmSound, unmuteAlarmSound } from '~/utils/sounds';

const StyledButton = styled.button`
  padding: 0;
  margin: 0;
  background: transparent;
  border: none;
  outline: none;
  color: white;
  font-size: 20px;
  padding: 0 20px;
  height: 60px;
  max-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface Props {
  isDisabled: boolean;
  collapsed: boolean;
}

const MuteButton = memo(({ isDisabled, collapsed }: Props) => {
  const muted = useReactiveVar(alertSoundMutedVar);
  const isMuted = isDisabled ? true : muted;

  const toggleAlarm = () => {
    if (!isDisabled) {
      if (isMuted) {
        unmuteAlarmSound();
      } else {
        muteAlarmSound();
      }
    }
  };

  return (
    <Tooltip
      title={
        isDisabled
          ? undefined
          : i18n.t(isMuted ? 'muteButton.unmuteTooltipMessage' : 'muteButton.muteTooltipMessage')
      }
      placement="topLeft"
    >
      <StyledButton
        onClick={toggleAlarm}
        data-id="mute-btn"
        style={{
          fontSize: collapsed ? '20px' : '32px',
          cursor: isDisabled ? 'default' : 'pointer',
        }}
      >
        {isMuted ? <FaVolumeMute /> : <FaVolumeUp />}
      </StyledButton>
    </Tooltip>
  );
});

MuteButton.displayName = 'MuteButton';

export default MuteButton;
