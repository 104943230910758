'use client';

import { useReactiveVar } from '@apollo/client';
import {
  createContext,
  useContext,
  useMemo,
  useCallback,
  useState,
  useEffect,
  useRef,
  type ReactNode,
} from 'react';

import useQueryWithSubscriptionSubsidiaryCarrierList from '~/apollo/hooks/agent/useQueryWithSubscriptionSubsidiaryCarrierList';
import { currentSubsidiaryIdentifierVar } from '~/apollo/reactiveVariables/currentSubsidiaryIdentifierVar';
import { agentStatusPriority } from '~/config/constants';
import { DEFAULT_GPS_SENSOR } from '~/config/defaults';
import useAlarmsContext from '~/context/useAlarmsContext';
import useAuthenticationContext from '~/context/useAuthenticationContext';
import useCompanyFeatures from '~/hooks/useCompanyFeatures';
import { AGENT_STATUS, type Agent, type AgentAttributes } from '~/types/agent';
import { type AlarmWithCarrier } from '~/types/alarm';
import { EQUIPMENT_STATUS } from '~/types/equipment';
import {
  SENSOR_NAME_VARIABLE,
  SENSOR_STATUS_VARIABLE,
  type BrainStopSensorType,
  type PhysiologicalTemperatureSensorType,
  type BatterySensorType,
  type LteSignalStrengthSensorType,
  type OxygenSupplySensorType,
  type RadiationSensorType,
  type GasSensorType,
  type BodyTemperatureSensorType,
  type GpsSensorType,
} from '~/types/sensor';
import type { Vehicle } from '~/types/vehicle';
import computeAgentStatus from '~/utils/agent/computeAgentStatus';
import getAgentLastUpdate from '~/utils/agent/getAgentLastUpdate';
import getAgentNameWithAcronym from '~/utils/agent/getAgentNameWithAcronym';
import isAgentNew from '~/utils/agent/isAgentNew';
import { isToday } from '~/utils/dateTime';
import getEquipmentStatusDetails from '~/utils/equipment/getEquipmentStatusObject';
import parseJSON from '~/utils/parse/parseJSON';
import transformAttributes from '~/utils/parse/transformAttributes';
import computeVehicleLocation from '~/utils/vehicle/computeVehicleLocation';

const CONNECTION_LOST_THRESHOLD = 5 * 60 * 1_000;
const CONNECTION_LOST_CHECK_PERIOD = 5 * 1_000;

interface AgentsContextType {
  agents: Agent[];
  agentsWithOngoingAlarms: Agent[];
  getAgent: (id: string) => Agent | undefined;
  vehicles: Vehicle[];
  getVehicle: (id: string) => Vehicle | undefined;
  isLoading: boolean;
  isInitialLoading: boolean;
  refetchAgentsContext: () => Promise<void>;
}

const AgentsContext = createContext<AgentsContextType>({
  agents: [],
  agentsWithOngoingAlarms: [],
  getAgent: () => undefined,
  vehicles: [],
  getVehicle: () => undefined,
  isLoading: true,
  isInitialLoading: true,
  refetchAgentsContext: () => Promise.resolve(),
});

AgentsContext.displayName = 'AgentsContext';

export function AgentsContextProvider({ children }: { children: ReactNode }) {
  const { isAuthenticated } = useAuthenticationContext();
  const currentSubsidiaryIdentifier = useReactiveVar(currentSubsidiaryIdentifierVar); // Using reactive var directly because of dependency cycle with useTeams
  const { companyFeatures } = useCompanyFeatures();
  const [agents, setAgents] = useState<Agent[]>([]);
  const [vehicles, setVehicles] = useState<Vehicle[]>([]);
  const disconnectTimestampsRef = useRef<Record<string, string>>({});
  const [disconnectTimestampsIteration, setDisconnectTimestampsIteration] = useState<number>(
    Date.now(),
  );
  const { subsidiaryCarrierList, isLoading, refetch } =
    useQueryWithSubscriptionSubsidiaryCarrierList({
      subsidiaryID: currentSubsidiaryIdentifier,
      skip: !currentSubsidiaryIdentifier || !isAuthenticated,
    });

  const { ongoingAlarms } = useAlarmsContext();

  const agentsWithOngoingAlarms = useMemo(
    () => agents.filter((agent) => ongoingAlarms.some((alarm) => alarm.carrier.id === agent.id)),
    [agents, ongoingAlarms],
  );

  useEffect(() => {
    const populateDisconnectTimestampsRef = () => {
      let areTimestampsModified = false;
      subsidiaryCarrierList?.forEach(({ id, device }) => {
        // check for connection status
        const connectionItem = device?.[SENSOR_NAME_VARIABLE.connected]?.items[0];
        if (connectionItem?.value && connectionItem?.timestamp) {
          const isConnected = connectionItem.value === 'true';
          // clear if connected
          if (isConnected && disconnectTimestampsRef.current[id]) {
            delete disconnectTimestampsRef.current[id];
            areTimestampsModified = true;
          }
          // set timestamp if it hasn't been set yet
          if (!isConnected && !disconnectTimestampsRef.current[id]) {
            disconnectTimestampsRef.current[id] = connectionItem.timestamp;
            areTimestampsModified = true;
          }
        }
      });
      setDisconnectTimestampsIteration((prev) =>
        areTimestampsModified || Date.now() - prev > CONNECTION_LOST_THRESHOLD ? Date.now() : prev,
      );
    };
    populateDisconnectTimestampsRef();
    const intervalId = window.setInterval(
      populateDisconnectTimestampsRef,
      CONNECTION_LOST_CHECK_PERIOD,
    );
    return () => {
      window.clearInterval(intervalId);
    };
  }, [subsidiaryCarrierList]);

  useEffect(() => {
    setAgents(
      subsidiaryCarrierList?.map((carrier) => {
        // Attributes
        // ==============================================
        const attributesMap = transformAttributes<AgentAttributes>(carrier.attributes);

        // Connection history
        // ==============================================
        const connectionHistory = carrier.device?.[SENSOR_NAME_VARIABLE.connected_history]?.items
          ?.slice()
          .sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime());
        const firstConnectionToday = connectionHistory?.find(
          ({ value, timestamp }) => value === 'true' && isToday(timestamp),
        );
        const lastConnectionToday = connectionHistory
          ? [...connectionHistory]
              ?.reverse()
              ?.find(({ value, timestamp }) => value === 'true' && isToday(timestamp))
          : undefined;
        const hasConnectedToday = !!firstConnectionToday;

        // Disconnect timestamp
        // ==============================================
        const disconnectTimestamp = disconnectTimestampsRef.current[carrier.id];
        let connectionLost = false;
        if (disconnectTimestamp) {
          const difference = Date.now() - new Date(disconnectTimestamp).getTime();
          if (difference > CONNECTION_LOST_THRESHOLD) {
            connectionLost = true;
          }
        }

        // Brain stop button
        // ==============================================
        const lastBrainStop = carrier.device?.[SENSOR_NAME_VARIABLE.brainStop]?.items[0];
        const brainStop: BrainStopSensorType | null = lastBrainStop
          ? {
              value: parseJSON(lastBrainStop.value),
              timestamp: lastBrainStop.timestamp,
            }
          : null;

        // Mission start and end time
        // ==============================================
        let missionEndTimeISO =
          brainStop?.value?.stop && isToday(brainStop?.timestamp) ? brainStop?.timestamp : '';
        let missionStartTimeISO = firstConnectionToday?.timestamp ?? '';
        // If mission ended on another day, we set start of the mission to start of this day.
        if (missionEndTimeISO && !missionStartTimeISO) {
          missionStartTimeISO = new Date(new Date().setHours(0, 0, 0, 0)).toISOString();
        }
        // If agent got connected again after ending the mission, clear the timestamp.
        if (
          lastConnectionToday?.timestamp &&
          missionEndTimeISO &&
          new Date(lastConnectionToday?.timestamp).getTime() > new Date(missionEndTimeISO).getTime()
        ) {
          missionEndTimeISO = '';
        }

        // Offline
        // ==============================================
        const isOffline =
          brainStop?.value?.stop ||
          (companyFeatures.endOfDayReset && !hasConnectedToday) ||
          isAgentNew(carrier.device);

        // GPS
        // ==============================================
        const lastGps = carrier.device?.[SENSOR_NAME_VARIABLE.gps]?.items[0];
        const gps: GpsSensorType | null = lastGps
          ? {
              value: parseJSON(lastGps.value),
              timestamp: lastGps.timestamp,
            }
          : null;

        // Heart rate
        // ==============================================
        const lastHeartRate = carrier.device?.[SENSOR_NAME_VARIABLE.heartRate]?.items[0];
        const heartRate = lastHeartRate
          ? {
              value: Number(lastHeartRate.value),
              timestamp: lastHeartRate.timestamp,
            }
          : null;

        // Physiological temperature
        // ==============================================
        const lastPhysiologicalTemperature =
          carrier.device?.[SENSOR_NAME_VARIABLE.bodyMultiSensorV1]?.items[0];
        const physiologicalTemperature: PhysiologicalTemperatureSensorType | null =
          lastPhysiologicalTemperature
            ? {
                value: parseJSON(lastPhysiologicalTemperature.value),
                timestamp: lastPhysiologicalTemperature.timestamp,
              }
            : null;

        // Body temperature (deprecated)
        // ==============================================
        const lastBodyTemperature =
          carrier.device?.[SENSOR_NAME_VARIABLE.bodyTemperature]?.items[0];
        const bodyTemperature: BodyTemperatureSensorType | null = lastBodyTemperature
          ? {
              value: Number(lastBodyTemperature.value),
              timestamp: lastBodyTemperature.timestamp,
            }
          : null;

        // Gas
        // ==============================================
        const lastGas = carrier.device?.[SENSOR_NAME_VARIABLE.gas]?.items[0];
        const gas: GasSensorType | null = lastGas
          ? {
              value: parseJSON(lastGas.value),
              timestamp: lastGas.timestamp,
            }
          : null;

        // Radiation
        // ==============================================
        const lastRadiation = carrier.device?.[SENSOR_NAME_VARIABLE.radiation]?.items[0];
        const radiation: RadiationSensorType | null = lastRadiation
          ? {
              value: parseJSON(lastRadiation.value),
              timestamp: lastRadiation.timestamp,
            }
          : null;

        // Oxygen supply
        // ==============================================
        const lastOxygenSupply = carrier.device?.[SENSOR_NAME_VARIABLE.oxygenSupply]?.items[0];
        const oxygenSupply: OxygenSupplySensorType | null = lastOxygenSupply
          ? {
              value: parseJSON(lastOxygenSupply.value),
              timestamp: lastOxygenSupply.timestamp,
            }
          : null;

        // Battery
        // ==============================================
        const lastBattery = carrier.device?.[SENSOR_NAME_VARIABLE.battery]?.items[0];
        const battery: BatterySensorType | null = lastBattery
          ? {
              value: parseJSON(lastBattery?.value),
              timestamp: lastBattery?.timestamp,
            }
          : null;

        // LTE signal strength
        // ==============================================
        const lastLteSignalStrength =
          carrier.device?.[SENSOR_NAME_VARIABLE.lteSignalStrength]?.items[0];
        const lteSignalStrength: LteSignalStrengthSensorType | null = lastLteSignalStrength
          ? {
              value: parseJSON(lastLteSignalStrength?.value),
              timestamp: lastLteSignalStrength?.timestamp,
            }
          : null;

        // Agent alarms
        // ==============================================
        const agentOngoingAlarms: AlarmWithCarrier[] = ongoingAlarms.filter(
          (alarm) => alarm.carrier.id === carrier.id,
        );

        const codeName = carrier.name?.trim();

        // Agent object
        // ==============================================
        const agent: Agent = {
          id: carrier.id,
          email: carrier.email || null,
          name: codeName,
          completeName:
            [attributesMap.first_name, attributesMap.last_name].filter(Boolean).join(' ') ||
            codeName,
          deviceName: carrier.device?.name,
          attributes: {
            ...attributesMap,
            plate_number: companyFeatures.vehicles ? attributesMap?.plate_number : undefined,
          },
          team: attributesMap?.team || '',
          isConnected: carrier.device?.[SENSOR_NAME_VARIABLE.connected]?.items[0]?.value === 'true',
          isOffline,
          sensors: {
            gps,
            heartRate,
            physiologicalTemperature,
            bodyTemperature, // deprecated
            gas,
            radiation,
            oxygenSupply,
            battery,
            lteSignalStrength,
          },
          status: computeAgentStatus({
            hasAlarm: agentOngoingAlarms.length > 0,
            connectionLost,
            inSafeZone: Boolean(companyFeatures.safeZone && attributesMap?.in_safe_zone),
          }),
          equipmentStatus: {
            connectionLost: {
              status: EQUIPMENT_STATUS.no_error,
              healthy: connectionLost,
            },
            offline: {
              status: EQUIPMENT_STATUS.no_error,
              healthy: isOffline,
            },
            battery: getEquipmentStatusDetails({
              status: carrier.device?.[SENSOR_STATUS_VARIABLE.batteryStatus]?.items[0]?.value,
              connectionLost,
              isOffline,
            }),
            lteSignalStrength: getEquipmentStatusDetails({
              status:
                carrier.device?.[SENSOR_STATUS_VARIABLE.lteSignalStrengthStatus]?.items[0]?.value,
              connectionLost,
              isOffline,
            }),
            emergencyButton: getEquipmentStatusDetails({
              status: companyFeatures.emergencyButton
                ? carrier.device?.[SENSOR_STATUS_VARIABLE.emergencyStatus]?.items[0]?.value
                : undefined,
              connectionLost,
              isOffline,
            }),
            heartRate: getEquipmentStatusDetails({
              status: companyFeatures.heartRateSensor
                ? carrier.device?.[SENSOR_STATUS_VARIABLE.heartRateStatus]?.items[0]?.value
                : undefined,
              connectionLost,
              isOffline,
            }),
            physiologicalTemperature: getEquipmentStatusDetails({
              status: companyFeatures.physiologicalTemperatureSensor
                ? carrier.device?.[SENSOR_STATUS_VARIABLE.bodyMultiSensorV1Status]?.items[0]?.value
                : undefined,
              connectionLost,
              isOffline,
            }),
            bodyTemperature: getEquipmentStatusDetails({
              status: companyFeatures.bodyTemperatureSensor
                ? carrier.device?.[SENSOR_STATUS_VARIABLE.bodyTemperatureStatus]?.items[0]?.value
                : undefined,
              connectionLost,
              isOffline,
            }),
            gas: getEquipmentStatusDetails({
              status: companyFeatures.gasSensor
                ? carrier.device?.[SENSOR_STATUS_VARIABLE.gasStatus]?.items[0]?.value
                : undefined,
              connectionLost,
              isOffline,
            }),
            radiation: getEquipmentStatusDetails({
              status: companyFeatures.radiationSensor
                ? carrier.device?.[SENSOR_STATUS_VARIABLE.radiationStatus]?.items[0]?.value
                : undefined,
              connectionLost,
              isOffline,
            }),
            oxygenSupply: getEquipmentStatusDetails({
              status: companyFeatures.oxygenSupplySensor
                ? carrier.device?.[SENSOR_STATUS_VARIABLE.oxygenSupplyStatus]?.items[0]?.value
                : undefined,
              connectionLost,
              isOffline,
            }),
            traakFront: getEquipmentStatusDetails({
              status: companyFeatures.impactDetectionFront
                ? carrier.device?.[SENSOR_STATUS_VARIABLE.traakFrontStatus]?.items[0]?.value
                : undefined,
              connectionLost,
              isOffline,
            }),
            traakBack: getEquipmentStatusDetails({
              status: companyFeatures.impactDetectionBack
                ? carrier.device?.[SENSOR_STATUS_VARIABLE.traakBackStatus]?.items[0]?.value
                : undefined,
              connectionLost,
              isOffline,
            }),
          },
          missionStartTimeISO,
          missionEndTimeISO,
          connectionLost,
          lastUpdate: getAgentLastUpdate({ device: carrier.device, agentOngoingAlarms }),
          requested_video_stream_status: carrier.requested_video_stream_status || undefined,
          mergedConfigurations: {
            device: carrier.mergeConfigDevice_base,
            alarm: {
              emergency: carrier.mergeConfigAlarm_emergency,
              fall: carrier.mergeConfigAlarm_fall,
              attack: carrier.mergeConfigAlarm_attack,
              traak_front: carrier.mergeConfigAlarm_traak_front,
              traak_back: carrier.mergeConfigAlarm_traak_back,
              stress: carrier.mergeConfigAlarm_stress,
              abnormal_stops: carrier.mergeConfigAlarm_abnormal_stops,
              long_stops: carrier.mergeConfigAlarm_long_stops,
              gas_danger: carrier.mergeConfigAlarm_gas_danger,
              gas_high: carrier.mergeConfigAlarm_gas_high,
            },
          },
        };

        return agent;
      }) ?? [],
    );
  }, [
    ongoingAlarms,
    companyFeatures.heartRateSensor,
    companyFeatures.physiologicalTemperatureSensor,
    companyFeatures.bodyTemperatureSensor, // deprecated
    companyFeatures.gasSensor,
    companyFeatures.radiationSensor,
    companyFeatures.oxygenSupplySensor,
    companyFeatures.emergencyButton,
    companyFeatures.vehicles,
    companyFeatures.safeZone,
    companyFeatures.impactDetectionFront,
    companyFeatures.impactDetectionBack,
    companyFeatures.endOfDayReset,
    subsidiaryCarrierList,
    disconnectTimestampsIteration,
  ]);

  useEffect(() => {
    if (companyFeatures.vehicles) {
      const newVehicles: Vehicle[] = [];
      const vehiclesTemp: Vehicle[] = [];
      agents.forEach((agent) => {
        const plateNumber = agent.attributes.plate_number || '';
        if (!agent.isOffline && plateNumber) {
          let vehicle = vehiclesTemp.find(
            (vehiclesListItem) => vehiclesListItem.plateNumber === plateNumber,
          );
          if (!vehicle) {
            vehicle = {
              id: plateNumber,
              agents: [],
              gps: agent.sensors.gps || DEFAULT_GPS_SENSOR,
              plateNumber,
              status: AGENT_STATUS.warning,
              connectionLost: false,
            };
            vehiclesTemp.push(vehicle);
          }
          vehicle.agents.push(agent);
        }
      });
      vehiclesTemp.forEach((vehicle) => {
        const agentsSortedByPriority = [...vehicle.agents].sort(
          (a, b) => agentStatusPriority[b.status] - agentStatusPriority[a.status],
        );
        const highestPriorityAgent = agentsSortedByPriority[0];
        newVehicles.push({
          ...vehicle,
          agents: agentsSortedByPriority,
          gps: computeVehicleLocation(agentsSortedByPriority),
          status: highestPriorityAgent.status,
          connectionLost: highestPriorityAgent.connectionLost,
        });
      });
      setVehicles(newVehicles);
    } else {
      setVehicles([]);
    }
  }, [agents, companyFeatures.vehicles]);

  const getAgent = useCallback(
    (id: string): Agent | undefined => agents.find((agent: Agent) => agent.id === id),
    [agents],
  );

  const getVehicle = useCallback(
    (plateNumber: string): Vehicle | undefined =>
      vehicles.find((vehicle) => vehicle.plateNumber === plateNumber),
    [vehicles],
  );

  const isInitialLoading = isLoading && agents.length === 0;

  window.agentsNameWithAcronymMap = useMemo(() => {
    const map: Record<string, string> = {};
    agents.forEach((agent) => {
      map[agent.id] = getAgentNameWithAcronym(agent);
    });
    return map;
  }, [agents]);

  const value: AgentsContextType = useMemo(
    () => ({
      agents,
      agentsWithOngoingAlarms,
      getAgent,
      vehicles,
      getVehicle,
      isLoading,
      isInitialLoading,
      refetchAgentsContext: refetch,
    }),
    [
      agents,
      agentsWithOngoingAlarms,
      getAgent,
      vehicles,
      getVehicle,
      isLoading,
      isInitialLoading,
      refetch,
    ],
  );

  return <AgentsContext.Provider value={value}>{children}</AgentsContext.Provider>;
}

export default function useAgentsContext() {
  return useContext(AgentsContext);
}
