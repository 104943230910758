'use client';

import { memo, useMemo } from 'react';

import SectionList from '~/components/DetailsPopup/components/SectionList';
import i18n from '~/locales/i18n';
import type { Agent } from '~/types/agent';
import formatNumberWithUnit from '~/utils/parse/formatNumberWithUnit';

const REMAINING_TIME_UNIT = 'min';
const TANK_PRESSURE_UNIT = 'bar';
const TEMPERATURE_UNIT = '°C';

const DECIMALS = 0;

interface Props {
  agent: Agent | undefined;
  isHealthy: boolean;
}

const ContentOxygenSupply = memo(({ agent, isHealthy }: Props) => {
  const oxygenSupply = agent?.equipmentStatus.oxygenSupply.healthy
    ? agent?.sensors?.oxygenSupply
    : undefined;

  const remainingTimeValue = formatNumberWithUnit({
    number: isHealthy ? oxygenSupply?.value?.remaining_time : undefined,
    unit: REMAINING_TIME_UNIT,
    decimals: DECIMALS,
  });

  const tankPressureValue = formatNumberWithUnit({
    number: isHealthy ? oxygenSupply?.value?.pressure : undefined,
    unit: TANK_PRESSURE_UNIT,
    decimals: DECIMALS,
  });

  const temperatureValue = formatNumberWithUnit({
    number: isHealthy ? oxygenSupply?.value?.temp : undefined,
    unit: TEMPERATURE_UNIT,
    decimals: DECIMALS,
  });

  const list = useMemo(
    () => [
      {
        labelText: i18n.t('carrierDetailsPopup.oxygenSupply.remainingTime'),
        valueText: remainingTimeValue,
        style: { opacity: remainingTimeValue === 'n/a' ? 0.4 : 1 },
      },
      {
        labelText: i18n.t('carrierDetailsPopup.oxygenSupply.tankPressure'),
        valueText: tankPressureValue,
        style: { opacity: tankPressureValue === 'n/a' ? 0.4 : 1 },
      },
      {
        labelText: i18n.t('carrierDetailsPopup.oxygenSupply.temperature'),
        valueText: temperatureValue,
        style: { opacity: temperatureValue === 'n/a' ? 0.4 : 1 },
      },
    ],
    [remainingTimeValue, tankPressureValue, temperatureValue],
  );

  if (!agent) {
    return null;
  }

  return <SectionList elements={list} />;
});

ContentOxygenSupply.displayName = 'ContentOxygenSupply';

export default ContentOxygenSupply;
